body:has(#app) {
    background: url('img/erika/bg1-new-high.jpg') no-repeat fixed center top;
    background-size: cover;
    font-family: Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}

#app {
    padding: 9% 3% 0 6%;
}

#config {
    background-color: #ffffff;
    opacity: 0.85;
}

#config ul {
    clear: both;
}

.error {
    color: red;
}

.clearLeft {
    clear: left;
    margin-left: 10px;
}

#app .round {
    display: inline-block;
    clear: both;
    margin-right: 20px;
    margin-bottom: 6%;
}

#app .dryRun {

}

#app .round .courtName {
    font-size: 2em;
}

.delete {
    height: 0.7em;
    margin-left: 0.4em;
    cursor: pointer;
}

.star {
    height: 0.7em;
    margin-right: 0.4em;
    cursor: pointer;
}

#app .courts {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

#app .ranges {
    display: block;
    margin: 0 0 2em 1em;
    font-size: 2em;
}

.col {
    display: inline-block;
    float: left;
    margin: 1em;
    max-width: 50%;
}

.tournamentSettings,
.presentationSettings,
.googleImport,
.timeSettings,
.playerViewSettings {
    line-height: 1.5;
    font-size: 1.2em;
}

.timeSettings .time {
    font-size: 2em;
}

#app .players {
    margin-bottom: 10px;
    float: left;
    clear: both;
    width: 100%
}

#app label,
#app .courtsToUse .showTenCourts label {
    display: block;
}

#app .courtsToUse div.showTenCourts {
    margin-left: 1em;
}

#app .players label,
#app .courtsToUse label {
    display: inline-block;
}

.noRoundMessageSetting label {
    margin-left: 10px;
}


.clearData {
    float: right;
}

.createNewRound {
    font-size: 1.3em;
}

#config button.clearData img {
    background-image: url('img/delete.png');
    width: 15px;
    margin-right: 2px;
}

#config .players span {
    display: block;
}

#config .player {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 0.9em;
    margin-right: 2px;
    cursor: pointer;
    float: left;
}

#config .player.digits3 {
    font-size: 0.7em;
}

#config .player.checked {
    opacity: 0.1;
}

#config .player input {
    display: none;
}

#config .player.genderM {
    border: 6px solid #89cff0;
}

#config .player.genderW {
    border: 6px solid #f4c2c2;
}

#config .player.genderU {
    border: 6px solid #000;
}

#config input[type=text] {
    width: 2em;
}

#config .googleImport input[type=text] {
    width: 27em;
}

#config .noRoundMessageSetting input[type=text] {
    width: 20em;
}

#config label span {
    display: inline-block;
    width: 21em;
}

#config .googleImport label span {
    display: inline-block;
    width: 8em;
}

#config .googleImport label span.labelDetails {
    font-size: 0.7em;
    width: 100%;
}

#config .googleImport label span.labelDetails .highlight {
    font-size: inherit;
    display: inline;
    color: green;
}

.exampleRoundError {
    float: left;
    color: red;
}

.playerStatsTrigger {
    cursor: pointer;
}

.loadingSpinner {
    position: fixed;
    width: 200px;
    height: 200px;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -100px;
}
