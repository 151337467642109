h1 {
	text-align: center;
}

#presentation {
	display: flex;
	position: absolute;
    height: 100%;
    width: 100%;
	background: url('img/erika/bg1-new-high.jpg') no-repeat;
	background-size: 100% 100%;
	justify-content: center;
	align-items: center;
}

#presentation .courts {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	height: 100%;
	width: 100%;

}

#currentRound {
	display: flex;
	position: absolute;
	height: 100%;
	width: 100%;
}

#currentRound .round {
	height: 66%;
	width: 93%;
	margin: auto;
}

#presentation .ranges {
	display: none;
}

#currentRound .noRoundMessage {
	margin: auto;
	text-align: center;
	font-size: 10vw;
}

#previousRound {
	position: absolute;
	bottom: 1px;
	left: 200px;
}

#previousRound .round h1 {
	font-size: 20pt;
}

#previousRound .noRoundMessage {
	display: none;
}

#currentRoundLabel {
	position: absolute;
	top: 170px;
	left: 250px;
	font-size: 20pt;
	font-weight: bolder;
	color: white;
}

#previousRoundLabel {
	position: absolute;
	left: 250px;
	bottom: 200px;
	font-size: 20pt;
	font-weight: bolder;
	color: white;
}